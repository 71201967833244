.App {
  font-family: sans-serif;
  text-align: center;
}

h2 {
  color: #77f;
}

h3,
h6 {
  margin-top: 5px;
  margin-bottom: 5px;
}
